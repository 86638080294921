import Breed from '@/models/Breed'

import { find, sortBy } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },

    loading: false,
  },

  mutations: {
    setBreedList(state, breeds) {
      state.list = breeds
    },

    setListLoading(state, value) {
      state.loading = value
    },

    insertBreed(state, breeds) {
      const _breeds = find(state.list, { id: breeds.id })

      if (_breeds) {
        Object.assign(_breeds, breeds)
      } else {
        state.list.push(breeds)
      }

      state.list = sortBy(state.list, ['name'])
    },
  },

  actions: {
    fetchBreed({ commit }) {
      return Breed.get()
    },

    addNewBreed({ commit }, data) {
      return new Breed(data).save()
    },
  },
}
