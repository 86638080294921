import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'
import options from './options'
import defaults from './defaults'
import settings from './settings'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    auth,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'users' },
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      children: [...defaults, ...options, settings],
    },
  ],
})
