export default [
  {
    path: 'inbox',
    name: 'inbox',
    component: () =>
      import(/* webpackChunkName: "inbox" */ '@/views/Home/Inbox'),
  },
  {
    path: 'users',
    name: 'users',
    component: () =>
      import(/* webpackChunkName: "users" */ '@/views/Home/Users'),
  },
  {
    path: 'users/:id',
    name: 'user.details',
    component: () =>
      import(
        /* webpackChunkName: "users-details" */ '@/views/Home/Users/UserDetails'
      ),
  },
  {
    path: 'content',
    name: 'content',
    component: () =>
      import(/* webpackChunkName: "content" */ '@/views/Home/Content'),
  },
  {
    path: 'notification',
    name: 'notification',
    component: () =>
      import(
        /* webpackChunkName: "push-notification" */ '@/views/Home/Notification'
      ),
  },
  {
    path: 'reports',
    name: 'reports',
    component: () =>
      import(/* webpackChunkName: "reports" */ '@/views/Home/Reports'),
  },
  {
    path: 'profile',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: "reports" */ '@/views/Home/Admin'),
  },
]
