export default [
  {
    path: 'species-and-breeds',
    name: 'species-and-breeds',
    component: () =>
      import(
        /* webpackChunkName: "species-and-breeds" */ '@/views/Home/Options/SpeciesAndBreeds'
      ),
  },
  {
    path: 'medication-types',
    name: 'medication-types',
    component: () =>
      import(
        /* webpackChunkName: "medication-types" */ '@/views/Home/Options/MedicationTypes'
      ),
  },
  {
    path: 'appointment-types',
    name: 'appointment-types',
    component: () =>
      import(
        /* webpackChunkName: "appointment-types" */ '@/views/Home/Options/AppointmentTypes'
      ),
  },
  {
    path: 'contact-types',
    name: 'contact-types',
    component: () =>
      import(
        /* webpackChunkName: "contact-types" */ '@/views/Home/Options/ContactTypes'
      ),
  },
  {
    path: 'medical-record-types',
    name: 'medical-record-types',
    component: () =>
      import(
        /* webpackChunkName: "medical-record-types" */ '@/views/Home/Options/MedicalRecordTypes'
      ),
  },
  {
    path: 'other-reminder-types',
    name: 'other-reminder-types',
    component: () =>
      import(
        /* webpackChunkName: "other-reminder-types" */ '@/views/Home/Options/OtherReminderTypes'
      ),
  },
]
