import Specie from '@/models/Specie'
import Api from '@/services/api'

import { find, sortBy, findIndex } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },

    loading: false,
  },

  mutations: {
    setSpeciesList(state, species) {
      state.list = species
    },

    setListLoading(state, value) {
      state.loading = value
    },

    insertNewSpecies(state, species) {
      state.list.push(species)

      state.list = sortBy(state.list, ['name'])
    },

    updateSpeciesCount(state, { id, method }) {
      const _species = find(state.list, { id })

      const _count =
        method === 'add' ? _species.breeds_count + 1 : _species.breeds_count - 1

      Object.assign(_species, {
        ..._species,
        breeds_count: _count < 0 ? 0 : _count,
      })
    },

    updateNewSpecies(state, specie) {
      const _species = find(state.list, { id: specie.id })

      Object.assign(_species, specie)
    },

    removeSpecie(state, specie) {
      const index = findIndex(state.list, { id: specie.id })

      if (index > -1) {
        state.list.splice(index, 1)
      }
    },
  },

  actions: {
    fetchSpecies({ commit }) {
      return Specie.get()
    },

    async addNewSpecies({ commit }, data) {
      const specie = await new Specie(data).save()
      commit('insertNewSpecies', specie)
    },

    async editSpecies({ commit }, formData) {
      const specie = await Api.put(`species/${formData.id}`, formData)
      commit('updateNewSpecies', specie.data.data)
    },

    async deleteSpecie({ commit }, formData) {
      await Api.delete(`species/${formData.id}`, formData)

      commit('removeSpecie', formData)
    },
  },
}
